import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { SectionHeader } from '../Shared/SectionHeader';
import { Wrapper } from '../Shared/Wrapper';

export function AboutLanding() {
  return (
    <Wrapper>
      <section
        className="flex flex-col md:flex-row pt-10 pb-10 justify-center md:justify-between items-center border-b-2"
        style={{ minHeight: 468 }}
      >
        <section className="w-full md:w-6/12 text-center">
          <section className="hidden md:flex md:mb-0 flex-row space-x-8 md:items-center justify-center">
            <StaticImage
              quality={95}
              formats={['webp', 'auto', 'avif']}
              width={400}
              height={400}
              src={`../../assets/images/landing.png`}
              alt="NSTW Logo"
              placeholder="tracedSVG"
            />
          </section>
        </section>

        <section className="w-full md:w-6/12 text-center md:text-left">
          <SectionHeader title="Science for the People" align="center" />

          <p className="text-lg mb-10">
            The NSTW highlights the significant contributions of science and
            technology to national development and has become a platform for
            heralding S&T advocacy in the country.
          </p>

          <Link
            to="/about"
            className="text-primary-1 font-medium uppercase underline hover:opacity-80"
          >
            I Want to Know More →
          </Link>
        </section>
      </section>
    </Wrapper>
  );
}
