import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Wrapper } from '../Wrapper';
import { HeroTitle } from './HeroTitle';
import { StaticImage } from 'gatsby-plugin-image';

export function Hero() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            eventTheme
            eventSchedule
            office
            officeRegion
          }
        }
      }
    `,
  );

  return (
    <section className="bg-primary-3">
      {/* <section className="bg-primary-3 pt-6 pb-8 transform skew-y-2"> */}
      <section className="bg-primary-3 pt-6 pb-8">
        <Wrapper>
          {/* <section className="transform -skew-y-2"> */}
          <section className="">
            <section className="w-full flex flex-row justify-between space-x-6">
              <section style={{ width: 800 }}>
                <HeroTitle siteData={site.siteMetadata} />
              </section>
              <section className="hidden lg:block">
                <StaticImage
                  quality={95}
                  height={500}
                  width={475}
                  formats={['webp', 'auto', 'avif']}
                  src={`../../../assets/images/banner_icon.png`}
                  alt="NSTW"
                  placeholder="tracedSVG"
                />
              </section>
            </section>
          </section>
        </Wrapper>
      </section>
    </section>
  );
}
