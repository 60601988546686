import { Dialog, Transition } from '@headlessui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Wrapper } from './Shared/Wrapper';

export function Highlights() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);

  function closeHandler() {
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={closeHandler}
      >
        <Wrapper>
          <div className="flex items-end justify-center text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-6/12">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="block">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-28 w-28">
                      <StaticImage
                        quality={95}
                        formats={['webp', 'auto', 'avif']}
                        width={210}
                        height={120}
                        src={`../assets/images/nstw_logo.png`}
                        alt="NSTW Logo"
                        placeholder="tracedSVG"
                      />
                    </div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-xl mb-5 leading-6 font-bold text-gray-900"
                      >
                        Maraming Salamat, ka-Agham! ❤️
                      </Dialog.Title>

                      <div className="mb-5 px-4 w-full">
                        <div className="text-sm text-gray-500">
                          <p>
                            Highlights of the National Science and Technology
                            Week in Region 1. See you next year!
                          </p>
                          <p>
                            For the national celebration updates visit{' '}
                            <a
                              href="http://nstw.dost.gov.ph/"
                              target="_blank"
                              className="text-primary-1 font-bold focus:border-opacity-0"
                            >
                              www.nstw.dost.gov.ph
                            </a>
                          </p>
                        </div>
                      </div>

                      <div className="px-4 w-full">
                        <div className="aspect-w-16 aspect-h-9 h-69">
                          <iframe
                            src={
                              'https://www.youtube.com/embed/lvfTz9pupSg?autoplay=1&modestbranding=1&fs=0'
                            }
                            frameBorder="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mb-3">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-1 text-base font-medium text-white hover:opacity-80 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeHandler}
                  >
                    Continue →
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Wrapper>
      </Dialog>
    </Transition.Root>
  );
}
