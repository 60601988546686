import { CalendarIcon } from '@heroicons/react/outline';
import { navigate } from 'gatsby';
import React from 'react';
import { Button } from '../Button';

type Props = {
  siteData: any;
};

export function HeroTitle({ siteData }: Props) {
  return (
    <section className="flex flex-col h-full justify-center">
      <section className="mb-10 text-left">
        <h5 className="text-xl md:text-2xl text-primary-1 mb-5 font-sans">
          {siteData.office}
        </h5>
        <h1 className="text-3xl md:text-4xl mb-5 uppercase text-primary-1 font-hero leading-0">
          {siteData.title} IN {siteData.officeRegion}
        </h1>
        <p className="text-4xl md:text-6xl mb-5  text-primary-2 font-hero-rounded uppercase">
          {siteData.eventTheme}
        </p>
        <p className="text-md md:text-xl text-primary-1 flex items-center space-x-2 md:justify-start uppercase font-sans">
          <CalendarIcon className="w-6 h-6" />
          <span>{siteData.eventSchedule}</span>
        </p>
      </section>

      <section
        className="flex 
                  flex-col space-y-2
                  md:space-y-0 md:space-x-2 md:flex-row font-sans
                  "
      >
        {/* <Button onClick={() => navigate('/activities')} size="md" primary>
          Attend Our Activities
        </Button> */}

        <section
          tabIndex={0}
          className="flex items-center justify-center"
          onClick={() => navigate('/exhibit')}
        >
          <Button size="md" primary>
            Visit the Virtual Exhibit →
          </Button>
        </section>
      </section>
    </section>
  );
}
