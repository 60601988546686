import React from 'react';
import { OptionEnum, WebinarStatusEnum } from '../../graphql/__types';
import { Wrapper } from '../Shared/Wrapper';
import { SectionHeader } from '../Shared/SectionHeader';
import { WebinarList } from './WebinarList';

export function WebinarLanding() {
  return (
    <Wrapper>
      <section className="w-full py-16">
        <SectionHeader
          title="Webinars"
          description={
            <span className="text-lg">
              Join our celebration and attend our webinars
            </span>
          }
          align="center"
        />
        <WebinarList
          status={WebinarStatusEnum.Waiting}
          location="landing"
          count={8}
          isWebinar={OptionEnum.Yes}
        />
      </section>
    </Wrapper>
  );
}
