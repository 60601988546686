import { Link } from 'gatsby';
import React from 'react';
import { SectionHeader } from '../Shared/SectionHeader';
import { Wrapper } from '../Shared/Wrapper';

export function ActivityLanding() {
  return (
    <Wrapper>
      <section className="flex flex-col pt-10 pb-10 border-b-2">
        <SectionHeader title="Program of Activities" align="left" />

        <section className="flex flex-col md:flex-row md:space-x-8">
          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start">
              <section className="bg-primary-2 text-white p-6 text-center rounded-lg text-lg">
                <h1 className="flex flex-col justify-center uppercase">
                  <span>November</span>
                  <span className="font-bold">8</span>
                </h1>
              </section>

              <section className="text-lg font-semibold">
                <section className="mb-2 text-primary-2">
                  <p>Turnover and Blessing of PSTC-LU New Building</p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>NSTW Regional Celebration Opening Program</p>
                </section>

                <section className="mb-2 text-primary-2">
                  <p>Kapihan sa Ilocos</p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>Green My Enterprise in the New Normal</p>
                </section>

                <section className="mb-2 text-primary-2">
                  <p>
                    21st Century Learning Environment Model in the Adaptive
                    Normal
                  </p>
                </section>

                <section className="text-primary-1">
                  <p>Opening of the DOST 1 Virtual Technology Exhibits</p>
                </section>
              </section>
            </section>
          </section>

          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start mb-5">
              <section className="bg-primary-1 text-white p-6 text-center rounded-lg text-lg">
                <h1 className="flex flex-col justify-center uppercase">
                  <span>November</span>
                  <span className="font-bold">9</span>
                </h1>
              </section>

              <section className="text-lg font-semibold">
                <section className="mb-2 text-primary-2">
                  <p>Forum on DOST DRRM Related Technologies</p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>Master Class: Guide to Chemical Waste Management</p>
                </section>

                <section className="mb-2 text-primary-2">
                  <p>Webinar on Gender and Climate Change</p>
                </section>

                <section className="text-primary-1">
                  <p>STARBOOKS Stakeholders’ Regional Convention - Day 1</p>
                </section>
              </section>
            </section>
          </section>
        </section>

        <section className="flex flex-col md:flex-row md:space-x-8">
          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start">
              <section className="bg-primary-2 text-white p-6 text-center rounded-lg text-lg">
                <h1 className="flex flex-col justify-center uppercase">
                  <span>November</span>
                  <span className="font-bold">10</span>
                </h1>
              </section>

              <section className="text-lg font-semibold">
                <section className="mb-2 text-primary-2">
                  <p>TechTalk: e-Nutribun and other FNRI technologies</p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>
                    Occupational Safety and Health in the Workplace for MSMEs
                  </p>
                </section>

                <section className="mb-2 text-primary-2">
                  <p>STARBOOKS Stakeholders’ Regional Convention - Day 2</p>
                </section>
              </section>
            </section>
          </section>

          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start mb-5">
              <section className="bg-primary-1 text-white p-6 text-center rounded-lg text-lg">
                <h1 className="flex flex-col justify-center uppercase">
                  <span>November</span>
                  <span className="font-bold">11</span>
                </h1>
              </section>

              <section className="text-lg font-semibold">
                <section className="mb-2 text-primary-2">
                  <p>Fundamentals of Liquid Hand Soap Making</p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>Business Continuity Planning and Management</p>
                </section>

                <section className="mb-2 text-primary-2">
                  <p>
                    WEE Share, We Care: An Innovative Initiative to Support
                    Women Entrepreneurs
                  </p>
                </section>
              </section>
            </section>
          </section>
        </section>

        <section className="flex flex-col md:flex-row md:space-x-8">
          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start">
              <section className="bg-primary-2 text-white p-6 text-center rounded-lg text-lg">
                <h1 className="flex flex-col justify-center uppercase">
                  <span>November</span>
                  <span className="font-bold">12</span>
                </h1>
              </section>

              <section className="text-lg font-semibold">
                <section className="mb-2 text-primary-2">
                  <p>
                    Service Quality Webinar: Building a Pandemic-proof Customer
                    Relations Service
                  </p>
                </section>

                <section className="mb-2 text-primary-1">
                  <p>NSTW Regional Celebration Closing Program</p>
                  <p>
                    DOST-1 Awards: Recognizing Resillency, Initiatives and
                    Excellence admist Pandemic
                  </p>
                </section>
              </section>
            </section>
          </section>

          <section className="w-full md:w-6/12 mb-5">
            <section className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-start mb-5">
              <section className="bg-secondary-2 p-10 rounded-lg">
                <p className="text-lg mb-10 text-white">
                  Come and join us in the regional celebration of the National
                  Science and Technology Week (NSTW) on November 8-12, 2021!
                </p>

                <section className="flex flex-col space-y-5">
                  <Link
                    to="/activities"
                    className="text-white font-medium uppercase underline hover:opacity-80"
                  >
                    Click here to join us online →
                  </Link>

                  <Link
                    to="/contests"
                    className="text-white font-medium uppercase underline hover:opacity-80"
                  >
                    Want to show-off your talent? →
                  </Link>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
