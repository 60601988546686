import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby-link';
import { ExhibitCarouselItem } from './ExhibitCarouselItem';

export function ExhibitCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section className="mb-5">
      <Slider {...settings}>
        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso1.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/lobby')}
          />
        </ExhibitCarouselItem>

        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso2.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/kabuhayan-cluster-a')}
          />
        </ExhibitCarouselItem>

        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso3.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/kabuhayan-cluster-b')}
          />
        </ExhibitCarouselItem>

        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso4.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/kalusugan')}
          />
        </ExhibitCarouselItem>

        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso4.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/kaayusan')}
          />
        </ExhibitCarouselItem>

        <ExhibitCarouselItem>
          <StaticImage
            className="cursor-pointer hover:opacity"
            quality={95}
            formats={['webp', 'auto', 'avif']}
            width={1080}
            height={720}
            src={`../../../assets/images/iso4.jpg`}
            alt="NSTW Logo"
            placeholder="tracedSVG"
            onClick={() => navigate('/exhibit/kinabukasan')}
          />
        </ExhibitCarouselItem>
      </Slider>
    </section>
  );
}
