import { navigate } from 'gatsby-link';
import React from 'react';
import { Button } from '../Shared/Button';
import { SectionHeader } from '../Shared/SectionHeader';
import { Wrapper } from '../Shared/Wrapper';
import { ExhibitCarousel } from './ExhibitCarousel';

export function ExhibitLanding() {
  return (
    <>
      <Wrapper>
        <section className="flex flex-col pt-10 pb-10 border-b-2">
          <SectionHeader
            title="Join Us Virtually"
            description={
              <span className="block md:w-4/12 mx-auto">
                NSTW in Region 1 goes virtual! Visit our virtual exhibit and get
                a chance of winning some prizes
              </span>
            }
            align="center"
          />
          <ExhibitCarousel />

          <section className="mt-10 flex justify-center">
            <Button primary onClick={() => navigate('/exhibit')} size="lg">
              Go to our Virtual Exhibit →
            </Button>
          </section>
        </section>
      </Wrapper>
    </>
  );
}
