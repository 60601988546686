import React from 'react';
import { PublicLayout } from '../components/Shared/PublicLayout';
import SeoHeader from '../components/Shared/SeoHeader';
import { Hero } from '../components/Shared/Hero';
import { WebinarLanding } from '../components/Webinar/WebinarLanding';
import { AboutLanding } from '../components/About/AboutLanding';
import { ActivityLanding } from '../components/Activity/ActivityLanding';
import { ExhibitLanding } from '../components/Exhibit/ExhibitLanding';
import { MessageLanding } from '../components/Shared/Message';
import { Highlights } from '../components/Highlights';

export default function IndexPage() {
  return (
    <PublicLayout>
      <SeoHeader title="Home" />
      <Hero />
      <MessageLanding />
      <ExhibitLanding />
      <AboutLanding />
      <ActivityLanding />
      <WebinarLanding />
      <Highlights />
    </PublicLayout>
  );
}
