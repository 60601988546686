import React from 'react';
import { SectionHeader } from './SectionHeader';
import { Wrapper } from './Wrapper';

export function MessageLanding() {
  return (
    <section className="py-12" id="message">
      <Wrapper>
        <SectionHeader title="WELCOME TO NSTW IN REGION 1" align="center" />

        <section className="max-w-10xl md:w-8/12 mx-auto">
          <div className="aspect-w-16 aspect-h-9 h-69">
            <iframe
              src={
                'https://www.youtube.com/embed/DKEvknA4qjM?autoplay=1&modestbranding=1&fs=0'
              }
              frameBorder="0"
            />
          </div>
          <section className="text-center mt-10 leading-none uppercase text-lg">
            <h1 className="font-bold">Dr. Armando Q. Ganal, CESO III</h1>
            <span>Regional Director</span>
          </section>
        </section>
      </Wrapper>
    </section>
  );
}
